<template>
  <el-dialog draggable overflow
             v-model="courseDialog"
             :title="cardItem.name+' - 课程'"
             width="1520px"
             align-center>
    <el-dialog draggable overflow
               v-model="addCourseRuleDialog"
               width="1400px"
               :title="changeTitle"
               append-to-body
               @close="closeCourse"
               @open="openCourse"
    >
      <el-tabs v-model="activeName" class="demo-tabs" @tab-change="tabChange" @tab-click="handleClick">
        <el-tab-pane label="课程设置" name="first">
          <div class="El-dialog-div">
            <el-form
                ref="cardRules"
                :model="addCourseRule"
                :rules="cardRules"
                label-width="80px"
                class="demo-ruleForm">
              <h3 style="margin:20px 0">课程设置 </h3>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="卡片名称" prop="name">
                    <el-input v-model="addCourseRule.name" class="addcardStyle"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="消课方式" prop="subtractCourseWay">
                    <el-select :disabled="disAbled" v-model="addCourseRule.subtractCourseWay" placeholder="消课方式"
                               class="handle-select mr10  addcardStyle">
                      <el-option v-for="(item,index) in choice.subtractCourseWayList " :key="index"
                                 :label="item.dictLabel"
                                 :value="item.dictValue"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="销售类型" prop="cardMarketType">
                    <el-select v-model="addCourseRule.cardMarketType" class="addcardStyle">
                      <el-option v-for="(e,i) in cardTypeList" :key="i" :label="e.dictLabel"
                                 :value="e.dictValue*1"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="可视权限" prop="authority">
                    <el-select v-model="addCourseRule.authority" placeholder="可视权限" class="addcardStyle">
                      <el-option v-for="(item, index) in choice.authorityList " :key="index"
                                 :label="item.dictLabel"
                                 :value="item.dictValue"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="6">
                  <el-form-item label="价格" prop="decMoney">
                    <el-input-number
                        v-model="addCourseRule.decMoney"
                        :precision="2"
                        max="99999"
                        :step="0.01"
                        class="addcardStyle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="课单价" prop="courseUnitPrice">
                    <el-input-number
                        v-model="addCourseRule.courseUnitPrice"
                        :precision="2"
                        max="9999"
                        :step="0.01"
                        @blur="changeNumber('addCourseRule.courseUnitPrice')"
                        class="addcardStyle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="课程数量" prop="courseNumber">
                    <el-input-number
                        v-model="addCourseRule.courseNumber"
                        :precision="1"
                        max="999"
                        @blur="changeNumber('addCourseRule.courseNumber')"
                        :step="0.5"
                        class="addcardStyle"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="库存数量" prop="inventory">
                    <el-input-number
                        max="99999"
                        @blur="changeNumber('addCourseRule.inventory')"
                        v-model="addCourseRule.inventory"
                        :step="1"
                        class="addcardStyle"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <div style="width: 600px;position: relative;">
                <el-form-item label="收据备注" prop="receiptDescription">
                  <el-input
                      v-model="addCourseRule.receiptDescription"
                      :rows="4"
                      type="textarea"
                      placeholder="请填写收据备注信息"
                  ></el-input>
                </el-form-item>
                <el-form-item v-if="!disAbled" label="角色" prop="roleId"
                              style="position: absolute;right: -340px;top: 20px;">
                  <el-select v-model="addCourseRule.roleId" filterable placeholder="请选择">
                    <el-option v-for="(item,index) in role" :key="index" :label="item.name"
                               :value="item.id"></el-option>
                  </el-select>
                  <div class="addRole" @click="roleDialog = true">+</div>
                </el-form-item>
                <div v-if="disAbled" style="position: absolute;right: -500px;top: 20px;color: red;font-weight: bolder;">
                  <h3>注意事项：</h3>
                  <p style="margin-top:8px">1、调整课程价格：已支付，待支付订单金额不会改变。</p>
                  <p style="margin-top:4px">2、调整课单价：已购，新购，课单价都已调整后的课单价为准。</p>
                  <p style="margin-top:4px">3、调整课程数量：已支付，待支付，课程数量不会改变。</p>
                  <p style="margin-top:4px">4、调整收据备注：已支付的收据备注信息不会改变。</p>
                </div>
              </div>
              <h3 v-if="!disAbled" style="margin:20px 0">日期设置
                <span v-if="addCourseRule.setDateWay==1" style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;开始日期结束日期必须填写，有效日期不可填写；购买后{{
                    dateFormat(addCourseRule.startDate)
                  }}&nbsp;至&nbsp;{{ dateFormat(addCourseRule.endDate) }}有效</span>
                <span v-if="addCourseRule.setDateWay==2" style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;开始日期结束日期不可填写，有效日期必须填写，使用后{{
                    addCourseRule.howManyDay
                  }}天内有效</span>
                <span v-if="addCourseRule.setDateWay==3" style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;开始日期结束日期不可填写，有效日期必须填写，购买后{{
                    addCourseRule.howManyDay
                  }}天内有效</span>
                <span v-if="addCourseRule.setDateWay==4" style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;开始日期、结束日期、有效日期必须填写,仅可在{{
                    dateFormat(addCourseRule.startDate)
                  }}&nbsp;至&nbsp;{{ dateFormat(addCourseRule.endDate) }}内使用{{ addCourseRule.howManyDay }}天</span>

                <span v-if="addCourseRule.setDateWay==5" style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;结束日期 必须填写&nbsp;&nbsp;</span>
              </h3>

              <h3 v-if="disAbled" style="margin:20px 0">日期设置
                <span v-if="addCourseRule.setDateWay==1"
                      style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;1-购买后{{ dateFormat(addCourseRule.startDate) }}&nbsp;至&nbsp;{{
                    dateFormat(addCourseRule.endDate)
                  }}有效</span>
                <span v-if="addCourseRule.setDateWay==2"
                      style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;2-使用后{{ addCourseRule.howManyDay }}天内有效</span>
                <span v-if="addCourseRule.setDateWay==3"
                      style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;3-购买后{{ addCourseRule.howManyDay }}天内有效</span>
                <span v-if="addCourseRule.setDateWay==4"
                      style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;4-仅可在{{ dateFormat(addCourseRule.startDate) }}&nbsp;至&nbsp;{{
                    dateFormat(addCourseRule.endDate)
                  }}内使用{{ addCourseRule.howManyDay }}天</span>
                <span v-if="addCourseRule.setDateWay==5"
                      style="font-size:12px;color:red">&nbsp;&nbsp;&nbsp;5-仅固定结束日期：提报日期 &nbsp;至&nbsp;{{
                    dateFormat(addCourseRule.endDate)
                  }}期间有效</span>
              </h3>
              <el-row v-if="!disAbled">
                <el-col :span="6">
                  <el-form-item label="设置类型" prop="setDateWay">
                    <el-select v-model="addCourseRule.setDateWay" placeholder="请选择"
                               class="handle-select mr10 addcardStyle">
                      <el-option v-for="(item,index) in choice.setDateWayList " :key="index"
                                 :label="item.dictLabel"
                                 :value="item.dictValue"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="开始日期" prop="startDate">
                    <el-date-picker
                        :disabled="addCourseRule.setDateWay== 1?false: addCourseRule.setDateWay == 4?false:true"
                        type="date"
                        style="width:180px"
                        placeholder="请选择开始日期"
                        v-model="addCourseRule.startDate"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="结束日期" prop="endDate">
                    <el-date-picker type="date"
                                    style="width:180px"
                                    :disabled="addCourseRule.setDateWay== 1?false: addCourseRule.setDateWay == 4?false:addCourseRule.setDateWay == 5?false:true"
                                    placeholder="请选择结束日期"
                                    v-model="addCourseRule.endDate"
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="有效天数" prop="howManyDay">
                    <el-input-number
                        :disabled="addCourseRule.setDateWay== 2?false: addCourseRule.setDateWay == 3?false:addCourseRule.setDateWay == 4?false:true"
                        v-model="addCourseRule.howManyDay"
                        :precision="1"
                        :step="1"
                        class="addcardStyle"
                    />
                  </el-form-item>
                </el-col>
              </el-row>

            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课程赠品" name="third">
          <div class="el-dialog-div">
            <sendCourse :choice="choice" @getInform="getInform" @regionList="regionList" :areaType="card.areaType"
                        :roleId="addCourseRule.roleId" :areaId="cardItem.areaId"
                        :editGift="editGift"/>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课程说明" name="second">
          <div class="el-dialog-div">
            <div style="height:40px"></div>
            <el-form :model="addCourseRule"
                     :rules="cardRules"
                     label-width="80px"
                     class="demo-ruleForm">

            </el-form>
            <div style="display:flex">
              <div style="width:20%;padding:20px;border: 1px solid skyblue;height: 300px;">
                <h4 style="color:red;margin-bottom: 20px;">注意事项：</h4>
                <p style='margin-bottom:20px'>1、标题字体大小为正常大小，行高为2.5
                  <br/>
                  字体加粗</p>
                <p>2、内容字体大小为10px，其余不设置</p>
              </div>
              <editor
                  ref="editor"
                  @getHtml="getHtml"
                  :addHtml="addHtml"
              />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <el-button @click="addCourseRuleDialog = false">取消</el-button>
        <el-button type="primary" @click="AddCourseRule()">确定</el-button>
      </template>
    </el-dialog>
    <el-dialog
        draggable overflow
        v-model="updateLoginDialog"
        width="1000px"
        title="修改记录"
        append-to-body
    >
      <el-tabs v-model="updateNamessss" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="修改记录" name="user">
          <el-table
              :data="updateLoginData"
              border
              class="table"
              ref="multipleTable"
              :row-style="{ height: 10 + 'px' }"
              :cell-style="{ padding: 5 + 'px' }"
              header-cell-class-name="table-header"
          >
            <el-table-column
                prop="fieldsName"
                label="修改内容"
                show-overflow-tooltip="true"
                width="100"
            ></el-table-column>
            <el-table-column
                prop="userName"
                label="修改人姓名"
                show-overflow-tooltip="true"
                width="100"
            >
            </el-table-column>
            <el-table-column prop="oldContent" label="修改前数据">
            </el-table-column>
            <el-table-column prop="newContent" label="修改后数据">
            </el-table-column>
            <el-table-column prop="createTime" label="修改时间">
            </el-table-column>
            <el-table-column prop="note"
                             show-overflow-tooltip="true"
                             width="100"
                             label="备注">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="申请记录" name="apply">
          <el-table
              :data="applyData"
              border
              class="table"
              ref="multipleTable"
              :row-style="{ height: 10 + 'px' }"
              :cell-style="{ padding: 5 + 'px' }"
              header-cell-class-name="table-header"
          >
            <el-table-column prop="status" label="状态">
              <template #default="scope">
                <span v-if="scope.row.status == 1">审核中</span>
                <span v-if="scope.row.status == 2">已通过</span>
                <span v-if="scope.row.status == 3">已拒绝</span>
              </template>
            </el-table-column>
            <el-table-column
                prop="createTime"
                label="创建时间"
            >
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <div style="height: 550px;display: flex;padding:0 10px 50px 10px;">
      <div class="choice-panel">
        <div style="overflow:auto;height: 500px;">
          <div v-for="(specItem,specIndex) in cardItem.specList" :key="specIndex">
            <div style="font-weight: bold;font-size: 13px">{{ specItem.name }}</div>
            <div style="display: flex;flex-wrap: wrap;margin: 14px 0 6px 0;">
              <div class="attribute" v-for="(attributeItem,index) in specItem.attributeList" :key="index"
                   @click="choiceAttribute(specIndex,index)"
                   :style="specItem.attributeList.attributeIndex == index ? 'background-color: #cfcc63;color:#fff;':''">
                {{ attributeItem.name }}
              </div>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="添加规格的属性值 如:淘宝选择商品颜色下的（白色、红色、绿色等...）"
                  placement="top"
              >
                <div @click.stop="openPopover">
                  <el-popover
                      placement="bottom"
                      :width="200"
                      trigger="click">
                    <div style="text-align: center">
                      <el-input v-model="addSpecOrAttribute.name" class="w-50 m-2" placeholder="请输入属性值名称"/>
                      <button class="button" @click="createSpecOrAttribute(specItem.id)">添加</button>
                    </div>
                    <template #reference>
                      <div class="add-attribute"> +</div>
                    </template>
                  </el-popover>
                </div>
              </el-tooltip>
            </div>
          </div>
          <el-tooltip
              class="box-item"
              effect="dark"
              content="添加卡片的规格 如:淘宝商品下的（颜色、尺码、大小、版本等...）"
              placement="top"
          >
            <div @click.stop="openPopover">
              <el-popover
                  placement="bottom"
                  :width="200"
                  trigger="click">
                <div style="text-align: center">
                  <el-input v-model="addSpecOrAttribute.name" class="w-50 m-2" placeholder="请输入规格名称"/>
                  <button class="button" @click="createSpecOrAttribute(null)">确定</button>
                </div>
                <template #reference>
                  <div class="add-spec"> +</div>
                </template>
              </el-popover>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div style="width: 77%">
        <div>
          <el-input size="small" placeholder="课程名称" class="handle-select mr10" v-model="courseQuery.name"></el-input>
          <el-select size="small" class="handle-select mr10" placeholder="消课方式" v-model="courseQuery.subtractCourseWay">
            <el-option v-for="(item,index) in choice.subtractCourseWayList" :key="index"
                       :label="item.dictLabel"
                       :value="item.dictValue"
            >
            </el-option>
          </el-select>
          <el-select size="small" v-model="courseQuery.authority" placeholder="可视权限" class="handle-select mr10">
            <el-option v-for="(item,index) in choice.authorityList " :key="index"
                       :label="item.dictLabel"
                       :value="item.dictValue"
            ></el-option>
          </el-select>
          <el-select
              size="small"

              v-model="courseQuery.state"
              placeholder="状态"
              class="handle-select mr10"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="开启" value="开启"></el-option>
            <el-option label="禁用" value="禁用"></el-option>
            <el-option label="关闭" value="关闭"></el-option>
          </el-select>
          <el-button
              type="primary"
              style="margin-left: 10px"
              @click="selectCourses()"
          >搜索
          </el-button>
        </div>
        <div>
          <el-table v-if="cardItem?.courseList?.length>0" :data="cardItem.courseList" border
                    :row-style="{height:2+'px'}"
                    :cell-style="{color:'#909399',fontSize:'12.5px',padding:'7px 0px'}"
                    class="table"
                    :header-cell-style="{background:'#ffff !important;'}"
                    style="border-radius: 10px"
                    :height="cardItem.courseList.length>7 ? '420px': cardItem.courseList.length*49+48+'px'"
          >
            <el-table-column width="90" align="center" show-overflow-tooltip="true" prop="id" label="ID"/>
            <el-table-column width="110" align="center" show-overflow-tooltip="true" prop="name" label="卡片名称"/>
            <el-table-column width="90" align="center" show-overflow-tooltip="true" prop="subtractCourseWayName"
                             label="消课方式"></el-table-column>
            <el-table-column width="100" align="center" show-overflow-tooltip="true" label="日期设置">
              <template #default="scope">
                <el-tooltip class="box-item" effect="dark" placement="top">
                  <template #content>
                    <div v-if="scope.row.setDateWay == 1">开始日期：{{ scope.row.startDate }}
                      <br/>结束日期：{{ scope.row.endDate }}
                    </div>
                    <div v-if="scope.row.setDateWay == 2">使用后{{ scope.row.howManyDay }}天内有效</div>
                    <div v-if="scope.row.setDateWay == 3">购买后{{ scope.row.howManyDay }}天内有效</div>
                    <div v-if="scope.row.setDateWay == 4">{{ scope.row.startDate }} 至 {{ scope.row.endDate }}
                      期间内可用{{ scope.row.howManyDay }}天
                    </div>
                    <div v-if="scope.row.setDateWay == 5">结束日期：{{ scope.row.endDate }}</div>
                  </template>
                  <el-tag circle>{{ scope.row.setDateWayName }}</el-tag>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="100" align="center" show-overflow-tooltip="true" prop="authorityName" label="可视权限">
            </el-table-column>
            <el-table-column width="90" align="center" show-overflow-tooltip="true" label="价格">
              <!-- {{ scope.row.money }} -->
              <template #default="scope">{{ scope.row.money / 100 }}元</template>
            </el-table-column>
            <el-table-column width="90" align="center" show-overflow-tooltip="true" label="课单价">
              <template #default="scope">{{ scope.row.courseUnitPrice }} 元</template>
            </el-table-column>
            <el-table-column width="90" label="课程数量" show-overflow-tooltip='true'>
              <template #default="scope">
                <div v-if="scope.row.cardGiftList.length>0">
                  <el-popover
                      placement="right"
                      :width="400"
                      trigger="hover"
                  >
                    <template #reference>
                      <div>
                        {{ scope.row.subtractCourseWay != 1 ? '期卡' : scope.row.courseNumber + '次' }}
                        <span style="color: #1da1f2;font-size: 12px"> 赠...</span>
                      </div>
                    </template>
                    <el-table :data="scope.row.cardGiftList"
                              border
                              :row-style="{height:2+'px'}"
                              :cell-style="{color:'#909399',fontSize:'12px',padding:'7px 0px'}"
                              class="table"
                              :header-cell-style="{background:'#ffff !important;'}"
                              style="border-radius: 10px">
                      <el-table-column width="100" show-overflow-tooltip="true" label="赠品名称">
                        <template #default="scopes">
                          {{ scopes.row.name }}
                        </template>
                      </el-table-column>
                      <el-table-column width="100" property="name" label="赠品类型">
                        <template #default="scopes">
                          {{ scopes.row.type == 1 ? '课程类型' : '器材类型' }}
                        </template>
                      </el-table-column>
                      <el-table-column width="100" property="name" label="赠送数量">
                        <template #default="scopes">
                          {{ scopes.row.giftNumber }}
                        </template>
                      </el-table-column>
                      <el-table-column property="state" label="赠品状态">
                        <template #default="scopes">
                          <el-switch
                              @click="updateSendState(scopes.row)"
                              v-model="scopes.row.state"
                              active-value="开启"
                              inactive-value="禁用"
                          >
                          </el-switch>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-popover>
                </div>
                <div v-else>
                  {{ scope.row.subtractCourseWay != 1 ? '期卡' : scope.row.courseNumber + '次' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="90" show-overflow-tooltip="true" prop="rule.name" label="销售类型">
              <template #default="scope">
                {{ scope.row.cardMarketType == 3 ? '正价卡' : scope.row.cardMarketType == 4 ? '拓展卡' : '引流卡' }}
              </template>
            </el-table-column>
            <el-table-column width="90" show-overflow-tooltip="true" prop="rule.name" label="剩余库存">
              <template #default="scope">{{ scope.row.inventory }} 单</template>
            </el-table-column>
            <el-table-column label="状态" width="80">
              <template #default="scope">
                <el-tooltip :content="scope.row.state=='开启'? '确定要禁用此卡片吗？':'确定要开启此卡片吗？'" placement="top">
                  <el-switch
                      @click="updateState(scope.row)"
                      v-model="scope.row.state"
                      active-value="开启"
                      inactive-value="禁用"
                  >
                  </el-switch>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="100" label="操作">
              <template #default="scope">
                <el-button size="small" style="padding: 0;margin: 0" type="text"
                           @click="handleEdit(scope.$index, scope.row)">编辑
                </el-button>
                <el-button size="small" style="padding: 0;margin: 0 0 0 10px" type="text"
                           @click="getUpdateLoginData(scope.row)">记录
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-tooltip
              class="box-item"
              effect="dark"
              :content="cardItem.isCourse==true?' 已有该规格课程，请重新选择左侧规格的属性值。':'添加课程规则'"
              placement="top"
          >
            <div class="add-course" v-if="cardItem.isCourse"> +</div>
            <div class="add-course" v-else @click="addCourseRuleDialog = true,courseDialog=false"> +</div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <el-dialog
        draggable overflow
        v-model="roleDialog"
        title="添加角色"
        width="1000px"
        append-to-body
    >
      <el-form :model="addRole" :rules="addRoleRule" ref="addRoleRef">
        <el-row>
          <el-col :span="8">
            <el-form-item label="角色名称" label-width="100px" prop="name">
              <el-input style="width:180px;" v-model="addRole.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="自增校区" label-width="100px" prop="areaType">
              <el-select v-model="addRole.isAll" class="m-2" placeholder="Select" size="large"
                         style="width:180px;margin-right: 10px">
                <el-option :value="0" label="否"/>
                <el-option :value="1" label="是"/>
              </el-select>
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  content=""
                  placement="top"
              >
                <template #content>后期管理员新增其他校区，是否也自动添加到当前角色里面。
                  <br/>是：表示后期管理员新增校区也将自动添加到当前角色。
                  <br/>否：表示仅当前选择的校区，后期新增校区不会添加到当前角色。
                  <br/>提示：如希望全部校区可见，建议选”是“。
                  <br/>提示：如需要部分校区可见，建议选”否“。
                </template>
                <span class="el-icon-question"></span>
              </el-tooltip>

            </el-form-item>
          </el-col>
        </el-row>
        <div v-for=" (item,index) in addRole.flagAreaCampus" :key="index">
          <el-row>
            <el-col :span="8">
              <el-form-item label="公司类型" label-width="100px" prop="areaType">
                <el-select v-model="item.flag" disabled
                           placeholder="类型" class="handle-select mr10" style="width:180px;">
                  <el-option label="中招体育" :value="1"></el-option>
                  <el-option label="少年" :value="2"></el-option>
                  <el-option label="高考体育" :value="4"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属公司" label-width="100px" prop="areaId">
                <el-cascader
                    v-model="item.cityId"
                    style="margin-right: 10px;width: 180px;"
                    placeholder="请选择区域"
                    :options="roleList"
                    collapse-tags
                    :collapse-tags-tooltip="true"
                    @change="selectCampusList(index,$event)"
                    :props="{  value:'id',label: 'name',children: 'children',multiple: true}"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="可视校区" label-width="100px" prop="">
                <el-cascader
                    style="margin-right: 10px;width: 180px;"
                    placeholder="请选择校区"
                    v-model="item.areaId"
                    :options="item.campusList"
                    collapse-tags
                    :collapse-tags-tooltip="true"
                    @change="changeAreaList"
                    :props="{multiple: true,  value:'id',label: 'name',children: 'children'}"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row>

        </el-row>

        <el-form-item label="备注说明" label-width="100px" prop="remark">
          <el-input
              v-model="addRole.remark"
              :rows="3"
              type="textarea"
              placeholder="请填写备注信息"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="roleDialog = false">取消</el-button>
        <el-button type="primary" :loading="addRoleLoading" @click="addRoleId">
          {{ addRoleLoading == true ? '请稍等...' : '添加' }}
        </el-button>
      </template>
    </el-dialog>
  </el-dialog>
</template>

<script>

import {
  addSpecOrAttribute,
  selectCourse, addCardAttributeRule,
  updateRuleById, listAreaTreeByCity,
  listAreaTreeByCityPost,
  addRoleAndRoleSubset,
  updateStateByRuleId,
  updateSendStateByGiftId,
  getRoleListByJsonItem,
  selectUpdateCardLog

} from "@/api/card";
import {listAreaTreeAddCoach} from "@/api/area.js";
import {selectDictByType} from '@/api/dict.js'
import editor from './wangeditor.vue'
import sendCourse from './sendCourse.vue'

export default {
  name: "Course",
  components: {
    editor,
    sendCourse
  },
  props: {
    card: Object,
    choice: Object
  },
  data() {
    return {
      addRoleLoading: false,
      AreaList: [],
      roleList: [],
      role: [],
      cardTypeList: [],
      areaId: null,
      roleDialog: false,
      updateNamessss:'user',
      changeTitle: '添加课程',
      updateLoginDialog: false,
      updateLoginData: [],
      applyData:[],
      disAbled: false,
      editGift: '',
      addHtml: '',
      activeName: 'first',
      cardRules: {
        name: [{
          required: true,
          message: '请选择卡片名称！',
          trigger: 'blur'
        },],
        subtractCourseWay: [{
          required: true,
          message: '请选择消课方式！',
          trigger: 'change'
        },],
        setDateWay: [{
          required: true,
          message: '请选择日期设置！',
          trigger: 'change'
        }],
        decMoney: [{
          required: true,
          message: '请添加价格！',
          trigger: 'blur'
        }],
        courseNumber: [{
          required: true,
          message: '请添加课程数量！',
          trigger: 'change'
        }],
        courseUnitPrice: [{
          required: true,
          message: '请添加课单价！',
          trigger: 'blur'
        }],
        cardMarketType: [{
          required: true,
          message: '请选择销售类型！',
          trigger: 'change'
        }],
        inventory: [{
          required: true,
          message: '请添加库存！',
          trigger: 'blur'
        }],
        state: [{
          required: true,
          message: '请选择状态！',
          trigger: 'change'
        }],
        receiptDescription: [{
          required: true,
          message: '请添加收据说明！',
          trigger: 'blur'
        }],
        authority: [{
          required: true,
          message: '请添加收据说明！',
          trigger: 'change'
        }],
        roleId: [{
          required: true,
          message: '请添加角色！',
          trigger: 'change'
        }],
      },
      addRoleRule: {
        name: [{
          required: true,
          message: '请选择卡片名称！',
          trigger: 'blur'
        },],
        areaType: [{
          required: true,
          message: '请选择公司类型！',
          trigger: 'change'
        },],
        // name: [{
        //   required: true,
        //   message: '请选择卡片名称！',
        //   trigger: 'blur'
        // },],
        areaId: [{
          required: true,
          message: '请选择所属公司！',
          trigger: 'change'
        },],
        remark: [{
          required: true,
          message: '请填写备注说明！',
          trigger: 'blur'
        },],
      },
      cardItem: {
        courseList: [],
        specList: [],
      },
      courseDialog: false,
      addCourseRuleDialog: false,
      addCourseRule: {
        endDate: null,
        startDate: null,
        description: '',
        roleId: null,
        specList: []
      },
      courseQuery: {
        cardId: null,
        specIdList: [],
        specAttributeIdList: [],
        name: "",
        subtractCourseWay: null,
        authority: null,
        state: ''
      },
      addSpecOrAttribute: {
        cardId: null,
        name: "",
        specId: null,
      },
      addRole: {
        flagAreaCampus: [
          {
            cityId: [],//城市id
            flag: 1,
            areaId: [],//校区id
            campusList: []
          }, {
            cityId: [],//城市id
            flag: 2,
            areaId: [],//校区id
            campusList: []
          }, {
            cityId: [],//城市id
            flag: 4,
            areaId: [],//校区id
            campusList: []
          }
        ],
        name: '',
        remark: '',
        isAll: 0,
      },
    }
  },
  watch: {
    card(val) {
      this.cardItem = val;
      this.areaId = val.areaId
      this.addRole.areaType = val.areaType
      this.addRole.areaId = val.areaId
      this.courseQuery.cardId = val.id;
      this.selectCourses();
      this.getRoleList(this.areaId)
    },
    'addRole.areaId'(val) {
      if (this.addRole.areaType && val) {
        listAreaTreeByCity(val, this.addRole.areaType).then(res => {
          if (res.code == 200) {
            this.getTypeList(res.data);
            this.AreaList = res.data
          } else {
            this.$message.error('查询失败')
          }
        })
      }
    },
    'addCourseRule.setDateWay'(val) {
      if (val == 1) {
        this.addCourseRule.howManyDay = ''
      }
      if (val == 2) {
        this.addCourseRule.endDate = null
        this.addCourseRule.startDate = null
      }
      if (val == 3) {
        this.addCourseRule.endDate = null
        this.addCourseRule.startDate = null
      }
    }
  },
  methods: {
    getUpdateLoginData(item) {
      this.updateLoginDialog = true;
      this.applyData = item.approvalProcess == null ? []:item.approvalProcess;
      selectUpdateCardLog(item.id).then(val => {
        this.updateLoginData = val.data;
        console.log(val)
      })
    },
    //获取角色
    async getRoleList() {
      let jsonItem = {'flag': this.card.areaType, 'cityId': this.card.areaId}
      await getRoleListByJsonItem(jsonItem).then(res => {
        if (res.code == 200) {
          this.role = res.data
        }
      })
    },
    //更改赠品状态
    updateSendState(row) {
      // console.log('BBB');
      updateSendStateByGiftId(row.id, row.state).then(res => {
        if (res.code == 200) {
          this.$message.success('修改成功')
        } else {
          this.$message.error('修改失败')
        }
      })
    },
    //更改卡片状态
    updateState(row) {
      updateStateByRuleId(row.id, row.state).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
        //还原一下修改前的数据
        this.selectCourses();
      })
    },
    //
    changeAreaList(e) {
      let areaListId = [];
      e.forEach(function (item) {
        areaListId.push(...item)
      })
      this.addRole.roleSubsetIdList = areaListId
    },
    //关闭弹框
    closeCourse() {
      this.changeTitle = '添加课程'
      Object.keys(this.addCourseRule).forEach(key => {
        this.addCourseRule[key] = null
      })
      this.addCourseRule = {
        roleId: null,
        specList: []
      }
      this.editGift = []
      this.disAbled = false
      this.$refs.editor.clear()
    },
    //打开弹窗
    openCourse() {
      // getListAll()
    },

    //父子传值
    regionList(e) {
      this.roleList = e
    },


    //添加角色的时候选择城市事件
    selectCampusList(index, item) {
      let areaFlag = this.addRole.flagAreaCampus[index].flag;
      let areaIdList = [...item.flat()]
      listAreaTreeByCityPost({areaFlag, areaIdList}).then(res => {
        if (res.code == 200) {
          this.addRole.flagAreaCampus[index].campusList = this.getTypeList(res.data);
        } else {
          this.$message.error('查询校区列表失败！')
        }
      })
    },
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    //添加角色Id
    addRoleId() {
      this.$refs['addRoleRef'].validate((valid) => {
        if (valid) {
          this.addRoleLoading = true;
          let role = this.addRole;
          for (const item of role.flagAreaCampus) {
            item.areaId = [...item.areaId.flat()]
            item.cityId = [...item.cityId.flat()]
          }
          addRoleAndRoleSubset(role).then(res => {
            this.addRoleLoading = false;
            if (res.code == 200) {
              this.$message.success('添加成功')
              this.roleDialog = false
              this.getRoleList(this.areaId)
            } else {
              this.$message.error('添加失败')
            }
          })
        } else {
          this.$message.error('请填写数据')
        }
      })
    },
    //编辑
    handleEdit(index, row) {
      this.changeTitle = '编辑课程'
      this.addCourseRule.id = row.id
      if (row.cardGiftList) {
        row.cardGiftList.forEach(item => {
          item.disAbled = true
        })
      }
      this.addCourseRule.name = row.name
      this.addCourseRule.subtractCourseWay = String(row.subtractCourseWay)
      if (row.subtractCourseWay) {
        this.disAbled = true
      }
      this.addHtml = this.tohtml(row.description)
      if (this.$refs.editor) {
        this.$refs.editor.phoneEditor.txt.html(this.addHtml)
      }
      this.addCourseRule.roleId = row.roleId
      this.addCourseRule.cardMarketType = row.cardMarketType
      this.addCourseRule.authority = String(row.authority)
      this.addCourseRule.decMoney = row.decMoney / 100
      this.addCourseRule.courseUnitPrice = row.courseUnitPrice
      this.addCourseRule.courseNumber = row.courseNumber
      this.addCourseRule.inventory = row.inventory
      this.addCourseRule.receiptDescription = row.receiptDescription
      this.addCourseRule.courseUnitPrice = row.courseUnitPrice
      this.addCourseRule.setDateWay = String(row.setDateWay)
      this.addCourseRule.endDate = row.endDate
      this.addCourseRule.howManyDay = row.howManyDay
      this.addCourseRule.startDate = row.startDate
      this.editGift = row.cardGiftList
      this.addCourseRuleDialog = true
    },
    tohtml(str) {
      var arrEntities = {
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'amp': '&',
        'quot': '"'
      };
      if (str) {
        return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
          return arrEntities[t]
        })
      }

    },
    //获取子组件数据
    getInform(giftList) {
      this.addCourseRule.cardGiftList = giftList || []
    },
    //关闭弹出框 清空数据
    createCardDialog() {
      this.addCourseRule = {}
    },
    //添加、编辑确定
    AddCourseRule() {
      this.$refs.cardRules.validate((valid) => {
        if (valid) {
          if (this.changeTitle == '编辑课程') {
            this.editLesson()

          } else {
            this.confirmAdd()
          }
          this.addCourseRuleDialog = false
        } else {
          this.$message.error('请填写课程设置')
        }
      })
    },
    confirmAdd() {
      if (this.addCourseRule.startDate != null) {
        this.addCourseRule.startDate = this.dateFormat(this.addCourseRule.startDate)
        this.addCourseRule.endDate = this.dateFormat(this.addCourseRule.endDate)
      }
      if (this.addCourseRule.specList.length == 0) {
        this.$message.error('请选择课程左侧属性值')
      } else {
        addCardAttributeRule(this.addCourseRule).then(res => {
          if (res.code == 200) {
            this.$message.success('添加成功！');
            this.selectCourses()
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    editLesson() {
      updateRuleById(this.addCourseRule).then(res => {
        if (res.code === 200) {
          this.$message.success(res.message);
          this.selectCourses()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
      return time;
    },
    getHtml(e) {
      this.addCourseRule.description = e
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    openDialog() {
      this.courseDialog = true;
    },
    /**
     * 查询课程
     */
    selectCourses() {
      selectCourse(this.courseQuery).then(res => {
        if (res.code === 200) {
          this.cardItem.courseList = res.data.cardAttributeRuleList;
          this.cardItem.isCourse = res.data.isCourse;
          if (this.courseQuery.specIdList.length === 0) {
            this.cardItem.isCourse = true
          }
        } else {
          this.$message.error("课程查询时出错了！");
        }
      })
    },

    /**
     * 添加卡片规格和添加规格属性值
     */
    createSpecOrAttribute(specId) {
      this.addSpecOrAttribute.specId = specId;
      this.addSpecOrAttribute.cardId = this.cardItem.id;
      addSpecOrAttribute(this.addSpecOrAttribute).then(res => {
        if (res.code == 200 && res.data == 1) {
          this.$emit("selectCourse", this.cardItem);
          this.$message.success("添加成功");
        } else {
          this.$message.error("添加失败！");
        }
      })
    },
    /**
     * 选择属性值
     */
    choiceAttribute(specIndex, attributeIndex) {
      if (this.cardItem.specList[specIndex].attributeList.attributeIndex == attributeIndex) {
        this.cardItem.specList[specIndex].attributeList.attributeIndex = null;
      } else {
        this.cardItem.specList[specIndex].attributeList.attributeIndex = attributeIndex;
      }
      let choiceAttributeId = [];
      let choiceSpecId = [];
      this.cardItem.specList.forEach(function (item) {
        if (item.attributeList.attributeIndex != null) {
          choiceAttributeId.push(item.attributeList[item.attributeList.attributeIndex].id);
          choiceSpecId.push(item.id);
        }
      })
      this.courseQuery.specAttributeIdList = choiceAttributeId;
      this.courseQuery.specIdList = choiceSpecId;
      let selectId = []
      for (let i = 0; i < choiceAttributeId.length; i++) {
        let test = [this.courseQuery.specIdList[i], this.courseQuery.specAttributeIdList[i]]
        selectId.push(test)
      }
      this.addCourseRule.specList = selectId
      this.addCourseRule.cardId = this.courseQuery.cardId
      this.selectCourses();
    }
  },
  created() {
    listAreaTreeAddCoach().then(res => {
      if (res.code == 200) {
        this.getTypeList(res.data);
        this.roleList = res.data
      } else {
        this.$message.error('查询失败')
      }
    })
    selectDictByType('cardType').then(res => {
      if (res.code == 200) {
        this.cardTypeList = res.data
      } else {
        this.$message.error('查询卡片类型失败')
      }
    })
  }
}
</script>

<style scoped>
.choice-panel {
  width: 23%;
  box-shadow: 0 0 10px rgba(59, 59, 59, 0.1);
  border-radius: 20px;
  padding: 30px 16px;
  margin-right: 30px;
  cursor: pointer;
}

.attribute {
  font-size: 12px;
  border: solid 1px #cfcc63;
  border-radius: 8px;
  color: #cfcc63;
  padding: 5px 14px;
  margin: 6px 10px;
}

.add-attribute {
  cursor: pointer;
  width: 21px;
  text-align: center;
  height: 16px;
  line-height: 16px;
  border: dotted 1px #A8ABB2;
  border-radius: 8px;
  color: #A8ABB2;
  padding: 5px 14px;
  margin: 6px 10px;
}

.add-spec {
  cursor: pointer;
  text-align: center;
  height: 26px;
  line-height: 26px;
  border: dotted 1px #A8ABB2;
  border-radius: 10px;
  color: #A8ABB2;
  padding: 5px 14px;
  margin: 30px 10px 0 10px;
}

.add-course {
  cursor: pointer;
  text-align: center;
  height: 46px;
  line-height: 46px;
  border: dotted 1px #A8ABB2;
  border-radius: 10px;
  color: #A8ABB2;
  padding: 5px 14px;
  margin: 20px 0;
}

.mr10 {
  margin-right: 10px;
}

.handle-select {
  width: 120px;
}

.table {
  margin-top: 20px;
}

.button {
  padding: 6px 16px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 4px;
  font-size: 10px;
  background-color: #1da1f2;
  color: white;
  border: #1da1f2;
  cursor: pointer;
}

.el-table__empty-block {
  height: 450px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 75px;
  margin-top: 25px;
  margin-bottom: 60px;
}

.addcardStyle {
  width: 180px;
}

.el-dialog-div {
  height: 49vh;
  /* overflow-x: hidden; */
}

.El-dialog-div {
  height: 47vh;
}

.addRole {
  position: absolute;
  right: -40px;
  top: 0;
  border: 1px solid gray;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
}
</style>
