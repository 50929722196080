<template>
  <div>
    <el-table
        :data="cardList"
        border
        v-loading="loadingDia"
        :row-style="{height:10+'px'}"
        :cell-style="{padding:2+'px'}"
        class="table"
        ref="multipleTable"
        height="606px"
        header-cell-class-name="table-header"
    >
      <el-table-column show-overflow-tooltip="true" prop="name" label="卡片名称">
      </el-table-column>
      <el-table-column width="110" align="center" label="缩略图">
        <template v-slot="scope">
          <el-image class="code" :src="scope.row.miniPicture"
                    :preview-src-list="[scope.row.miniPicture]">
            <template #error>
              <div class="code"></div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column width="110" align="center" label="详情图">
        <template v-slot="scope">
          <el-image class="code" :src="scope.row.productPicture"
                    :preview-src-list="[scope.row.productPicture]">
            <template #error>
              <div class="code"></div>
            </template>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" label="所属城市">
        <template #default="scope"
        >{{ scope.row.areaName != null ? scope.row.areaName + " - " + scope.row.areaTypeName : "" }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="name" label="可见权限">
        <template v-slot="scope">
          <span v-if="scope.row.role == 0">全部可见</span>
          <span v-if="scope.row.role == 1">教练可见</span>
          <span v-if="scope.row.role == 2">家长可见</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="secKillTime" label="上架时间">
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" prop="soldEndTime" label="下架时间">
      </el-table-column>
      <el-table-column show-overflow-tooltip="true" width="310" label="课程列表">
        <template #default="scope">
<!--          <el-button size="mini" type="primary" @click="combinationDialog(scope.row)">关联组合</el-button>-->
<!--          <el-button size="mini" type="primary" @click="selectCourse(scope.row)">查看</el-button>-->
          <el-button v-if="scope.row.isMoreSpec == 3" size="mini" type="primary" @click="combinationDialog(scope.row)">关联组合</el-button>
          <el-button v-else size="mini" type="primary" @click="selectCourse(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
          label="状态"
          width="110">
        <template #default="scope">
          <el-tooltip :content="scope.row.state=='开启'? '确定要禁用此卡片吗？':'确定要开启此卡片吗？'" placement="top">
            <el-switch
                @click="updateState(scope.row)"
                v-model="scope.row.state"
                active-value="开启"
                inactive-value="禁用"
            >
            </el-switch>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" align="center">
        <template #default="scope">
          <el-button
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
          >编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="pageIndex"
          :page-size="pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <Edit-card ref="EditCard"/>
  </div>
</template>

<script>
import EditCard from './editCard.vue'
import {updateState} from "@/api/card";

export default {
  name: "TableCard",
  components: {
    EditCard
  },
  props: {
    cardList: {},
    total: Number,
    PageIndex: Number
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      loadingDia: true
    }
  },
  methods: {
    combinationDialog(card) {
      this.$emit('combinationDialog', card);
    },
    selectCourse(card) {
      this.$emit('selectCourse', card);
    },
    handleEdit(index, row) {
      this.$refs.EditCard.getTable(row);
    },
    handlePageChange(e) {
      this.pageIndex = e
      this.$emit('sendPageIndex', e)
    },
    updateState(val) {
      updateState(val.id).then(res => {
        if (res.code == 200) {
          this.$message.success('修改成功')
        } else {
          this.$message.error('修改失败')
        }
      })
    }
  },
  watch: {
    total: {
      handler(newVal) {
        if (newVal) {
          this.pageTotal = newVal
        }
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    },
    PageIndex: {
      handler(newVal) {
        if (newVal) {
          this.pageIndex = newVal
        }
      },
      deep: true, // 深度监听
      immediate: true, //立即执行
    }
  }
}
</script>

<style scoped>
.table {
  margin-top: 20px;
}

.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #efefef;
}
</style>